import React, { useState } from 'react';
import { Box, Flex } from 'rebass';
import Hr from '../../../../../../Layout/Hr';
import Text from '../../../../../../Text';
import styled from 'styled-components';
import { useAlert } from 'react-alert';

const Button = styled(Text).attrs({ role: 'button' })`
  font-weight: 500;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`;

const ConfirmationPrompt = ({
  children,
  onHide,
  onConfirm,
  delay,
  confirmationMessage,
  disabled,
  showAlert,
}) => {
  const [didConfirm, setConfirm] = useState(false);
  const alert = useAlert();
  const canConfirm = !didConfirm && !disabled;
  let timeout;
  const onUndo = () => {
    clearTimeout(timeout);
    setConfirm(false);
    alert.removeAll();
  };
  return (
    <>
      <Box width={1}>
        <Box p={4}>{children}</Box>
        <Hr width={1} height="2px" />
        <Flex p={4} justifyContent="space-between">
          <Button color="secondary" onClick={onHide}>
            Cancel
          </Button>
          <Button
            variant="plain"
            disabled={!canConfirm}
            color={canConfirm ? 'chestnutRose' : 'tertiary'}
            onClick={() => {
              if (canConfirm) {
                setConfirm(true);
                onHide();
                if (showAlert) {
                  alert.show(undefined, {
                    type: 'success',
                    timeout: delay,
                    children: (
                      <Text color="white" css={{ display: 'flex' }}>
                        {confirmationMessage}&nbsp;
                        <Text
                          ml={2}
                          color="white"
                          role="button"
                          fontWeight="500"
                          css={{ cursor: 'pointer' }}
                          onClick={onUndo}
                        >
                          Cancel
                        </Text>
                      </Text>
                    ),
                  });
                }
                timeout = setTimeout(() => {
                  onConfirm().then(() => {
                    setConfirm(false);
                  });
                }, delay);
              }
            }}
          >
            Confirm
          </Button>
        </Flex>
      </Box>
    </>
  );
};

ConfirmationPrompt.defaultProps = {
  confirmationMessage: 'Data updated',
  delay: 5000,
  showAlert: true,
};

export default ConfirmationPrompt;
