import React from 'react';
import { space } from 'styled-system';
import styled from 'styled-components';
import { Flex } from 'rebass';
import Text from '../Text';

const StyledInput = styled.input.attrs(() => ({
  type: 'checkbox',
}))`
  ${space}
`;

const Label = styled(Text).attrs(() => ({
  as: 'label',
}))``;

const Checkbox = ({ label, ...props }) => {
  if (label) {
    return (
      <Flex>
        <StyledInput {...props} />
        <Label htmlFor={props.id} ml={2} variant="secondary">
          {label}
        </Label>
      </Flex>
    );
  }
  return <StyledInput {...props} />;
};

Checkbox.propTypes = {};

export default Checkbox;
