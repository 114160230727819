import React from 'react';
import { Flex, Box } from 'rebass';
import { Formik, Form, Field as FormikField } from 'formik';
import ReactDatePicker from 'react-datepicker';
import DeleteUser from '../DeleteUser';
import lazyFirebase from '../../../../../../../firebase/config';
import { accessLevels } from '../../../../../constants';
import Text from '../../../../../../Text';
import Select from '../../../../../../Select';
import Hr from '../../../../../../Layout/Hr';
import Card from '../../../../../../Card';
import Input from '../../../../../../Input';
import Button from '../../../../../../Button';
import useUpdateMember from '../../../../../../../utils/hooks/members/useUpdateMember';
import useMembershipLevels from '../../../../../../../utils/hooks/members/useMembershipLevels';
import useFirebaseCallable from '../../../../../../../utils/hooks/members/useFirebaseCallable';
import Alert from '../../../../../../Alert';
import { navigate } from 'gatsby';

const Field = props => (
  <Box {...props}>
    <FormikField {...props} />
  </Box>
);

const validateForm = values => {
  const errors = {};
  Object.keys(values).forEach(key => {
    if (values[key] === '' || values[key] === undefined) {
      errors[key] = 'Required';
    }
  });
  if (Object.keys(errors).length) {
    return errors;
  }
  return undefined;
};

const MembersAdminMemberPageForm = ({ member }) => {
  const [updateMember, { data, loading, error }] = useUpdateMember();
  const { data: membershipLevels } = useMembershipLevels();
  const [deleteFirebaseUser, { data: deleteData }] = useFirebaseCallable(
    'deleteFirebaseUser',
  );
  const onDelete = () =>
    deleteFirebaseUser({ uid: member?.uid }).then(() =>
      navigate('/members/admin'),
    );
  return (
    <>
      <Card mb={3}>
        <Formik
          initialValues={{
            ...member,
          }}
          enableReinitialize
          onSubmit={values =>
            updateMember({
              uid: member?.uid,
              ...values,
              membershipExpires:
                'toDate' in values.membershipExpires
                  ? values.membershipExpires
                  : lazyFirebase.firestore.Timestamp.fromDate(
                      values.membershipExpires,
                    ),
            })
          }
          validate={validateForm}
          validateOnBlur
        >
          <Form>
            <Flex p={[3, 4, 5]} m={-3} flexWrap="wrap" css={{ maxWidth: 640 }}>
              <Field width={[1, 1 / 2]} p={3} name="firstName">
                {({ field, meta }) => (
                  <Input
                    width={1}
                    inline
                    label="First name"
                    {...field}
                    {...meta}
                  />
                )}
              </Field>
              <Field width={[1, 1 / 2]} p={3} name="lastName">
                {({ field, meta }) => (
                  <Input
                    width={1}
                    inline
                    label="Last name"
                    {...field}
                    {...meta}
                  />
                )}
              </Field>
              <Field width={[1, 1 / 2]} p={3} name="email">
                {({ field, meta }) => (
                  <Input
                    width={1}
                    inline
                    label="Email"
                    type="email"
                    {...field}
                    {...meta}
                  />
                )}
              </Field>
              <Field width={[1, 1 / 2]} p={3} name="membershipExpires">
                {({ field, form, meta }) => (
                  <Input
                    width={1}
                    inline
                    label="Membership expiral"
                    // eslint-disable-next-line
                    as={({ inline, ...props }) => (
                      <ReactDatePicker {...props} />
                    )}
                    selected={
                      field?.value
                        ? new Date(
                            'toDate' in field.value
                              ? field.value.toDate()
                              : field.value,
                          )
                        : new Date()
                    }
                    onSelect={value =>
                      form.setFieldValue('membershipExpires', value)
                    }
                    {...meta}
                  />
                )}
              </Field>
              <Field width={[1, 1 / 2]} p={3} name="membershipLevel">
                {({ field }) => (
                  <>
                    <Text variant="secondary" mb={2}>
                      Membership level
                    </Text>
                    <Select
                      inline
                      width={1}
                      label="Membership level"
                      {...field}
                    >
                      {membershipLevels?.docs?.map(doc => {
                        const id = doc.id;
                        return (
                          <option key={doc.id} value={id}>
                            {doc.data().label}
                          </option>
                        );
                      })}
                    </Select>
                  </>
                )}
              </Field>
              <Field width={[1, 1 / 2]} p={3} name="isActive">
                {({ field, form }) => (
                  <>
                    <Text variant="secondary" mb={2}>
                      Status
                    </Text>
                    <Select
                      width={1}
                      inline
                      {...field}
                      onChange={e =>
                        form.setFieldValue(
                          field.name,
                          e.target.value === 'true',
                        )
                      }
                    >
                      {[
                        { label: 'Active', value: true },
                        { label: 'Paused', value: false },
                      ].map(status => (
                        <option
                          key={`isActive-status-${status.label}`}
                          value={status.value}
                        >
                          {status.label}
                        </option>
                      ))}
                    </Select>
                  </>
                )}
              </Field>
              <Field width={[1, 1 / 2]} p={3} name="isAdmin">
                {({ field, form }) => (
                  <>
                    <Text variant="secondary" mb={2}>
                      Access level
                    </Text>
                    <Select
                      width={1}
                      inline
                      {...field}
                      onChange={e =>
                        form.setFieldValue(
                          field.name,
                          e.target.value === 'true',
                        )
                      }
                    >
                      {accessLevels.map(status => (
                        <option
                          key={`isActive-status-${status.label}`}
                          value={status.value}
                        >
                          {status.label}
                        </option>
                      ))}
                    </Select>
                  </>
                )}
              </Field>
            </Flex>
            <Hr width={1} height={2} />
            <Flex justifyContent="flex-end" p={3}>
              <Button disabled={loading} variant="minimal" type="submit">
                Save
              </Button>
            </Flex>
          </Form>
        </Formik>
      </Card>
      {data && (
        <Alert mb={3} type="success">
          {member?.firstName} {member?.lastName} was successfully updated.
        </Alert>
      )}
      {deleteData && (
        <Alert mb={3} type="success">
          {member?.firstName} {member?.lastName} was successfully deleted.
        </Alert>
      )}
      {error && (
        <Alert mb={3} type="danger">
          {error ||
            error?.message ||
            'An unexpected error occurred. Please try again.'}
        </Alert>
      )}
      <DeleteUser member={member} onDelete={onDelete} />
    </>
  );
};

export default MembersAdminMemberPageForm;
