import React from 'react';
import MembersAdminMemberPageSessions from './Sessions';
import { Tabs, TabPanel } from 'react-tabs';
import { StyledTabList, StyledTab } from '../../../../../Tabs';
import MembersAdminMemberPageForm from './Form';

const MembersAdminMemberPage = ({ member }) => {
  if (member) {
    return (
      <>
        <Tabs>
          <StyledTabList mb={[4, 5]}>
            <StyledTab>Member data</StyledTab>
            <StyledTab>Sessions</StyledTab>
          </StyledTabList>
          <TabPanel>
            <MembersAdminMemberPageForm member={member} />
          </TabPanel>
          <TabPanel>
            <MembersAdminMemberPageSessions member={member} />
          </TabPanel>
        </Tabs>
      </>
    );
  }
  return null;
};

export default MembersAdminMemberPage;
