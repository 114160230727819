import styled from 'styled-components';
import { space } from 'styled-system';
import { TabList, Tab } from 'react-tabs';

export const StyledTabList = styled(TabList)`
  ${space}
  display: flex;
  align-items: center;
`;

export const StyledTab = styled(Tab)`
  ${space}
  cursor: pointer;
  transition: 0.2s;
  border-bottom: 2px solid transparent;
  color: ${({ theme }) => theme.colors.valhalla};
  font-weight: 500;
  &.react-tabs__tab--selected {
    color: ${({ theme }) => theme.colors.chestnutRose};
    border-color: ${({ theme }) => theme.colors.chestnutRose};
  }
`;

StyledTab.defaultProps = {
  px: 4,
  py: 3,
};
