export const statusLevels = [
  { label: 'Active', value: true },
  { label: 'Paused', value: false },
];

export const accessLevels = [
  { label: 'Admin', value: true },
  { label: 'Member', value: false },
];

export const membershipLevels = [
  {
    label: 'BBSH ASBIW Student',
  },
  {
    label: 'BBSH Faculty Membership',
  },
  {
    label: 'BBSH Year 1 Student',
  },
  {
    label: 'BBSH Year 2 Student',
  },
  {
    label: 'BBSH Year 3 Student',
  },
  {
    label: 'BBSH Year 4 Student',
  },
  {
    label: 'BI ASBIW Student',
  },
  {
    label: 'BI Faculty Membership',
  },
  {
    label: 'BI Year 1 Student',
  },
  {
    label: 'BI Year 2 Student',
  },
  {
    label: 'BI Year 3 Student',
  },
  {
    label: 'BI Year 4 Student',
  },
];
