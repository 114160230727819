import React, { useState } from 'react';
import { FiTrash } from 'react-icons/fi';
import { DialogDisclosure, useDialogState } from 'reakit/Dialog';
import { DialogBackdrop, Dialog } from '../../../../../../Dialog';
import Text from '../../../../../../Text';
import ConfirmationPrompt from '../../../Toolbar/Edit/Prompt';
import Checkbox from '../../../../../../Checkbox';
import Button from '../../../../../../Button';

const DeleteUser = ({ member, onDelete, ...props }) => {
  const dialog = useDialogState({ modal: true });
  const [disclosure, setDisclosure] = useState(false);
  return (
    <>
      <DialogDisclosure {...dialog}>
        <Button
          variant="minimal"
          css={{ display: 'flex', alignItems: 'center' }}
          {...props}
        >
          <FiTrash />
          &nbsp;Delete user account and data
        </Button>
      </DialogDisclosure>
      <DialogBackdrop {...dialog}>
        <Dialog {...dialog}>
          <ConfirmationPrompt
            onConfirm={onDelete}
            disabled={!disclosure}
            onHide={dialog.hide}
            confirmationMessage="Member is being deleted"
          >
            <Text mb={3} fontWeight="bold">
              Delete {member?.firstName} {member?.lastName}
            </Text>
            <Text variant="secondary" mb={3}>
              This will remove all member{"'"}s data. This action cannot be
              reversed.
            </Text>
            <Checkbox
              label="I understand this action cannot be undone."
              checked={disclosure}
              onChange={() => setDisclosure(!disclosure)}
            />
          </ConfirmationPrompt>
        </Dialog>
      </DialogBackdrop>
    </>
  );
};

export default DeleteUser;
