import React from 'react';
import qs from 'qs';
import useBbshMember from '../../../../utils/hooks/members/useBbshMember';
import MembersLayout from '../../../../components/Members/Layout';
import GateKeeper from '../../../../components/FirebaseAuth/GateKeeper';
import Container from '../../../../components/Container';
import MembersAdminMemberPage from '../../../../components/Members/Admin/ManageUsers/Member/Page';
import Alert from '../../../../components/Alert';
import SEO from '../../../../components/seo';

const MembersAdminUser = ({ location }) => {
  const { uid } = qs.parse(location.search, { ignoreQueryPrefix: true });
  const { member, isLoading, error } = useBbshMember(uid);
  return (
    <GateKeeper onlyAdmins>
      <SEO title={`${member?.firstName} ${member?.lastName}`} />
      <MembersLayout
        goBackLink="/members/admin"
        title={`${member?.firstName} ${member?.lastName}`}
      >
        <Container py={[3, 4]}>
          {error && (
            <Alert type="danger">
              {error?.message || error || 'An unexpected error occurred'}
            </Alert>
          )}
          <MembersAdminMemberPage member={member} isLoading={isLoading} />
        </Container>
      </MembersLayout>
    </GateKeeper>
  );
};

export default MembersAdminUser;
