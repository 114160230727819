import React from 'react';
import dropWhile from 'lodash/dropWhile';
import dropRightWhile from 'lodash/dropRightWhile';

import {
  FiChevronLeft,
  FiChevronRight,
  FiChevronsLeft,
  FiChevronsRight,
} from 'react-icons/fi';
import styled, { css } from 'styled-components';
import { Flex, Box } from 'rebass';
import { color } from 'styled-system';
import Text from '../Text';

const List = styled(Flex)`
  justify-content: center;
  align-items: center;
`;

const Page = styled(Box).attrs(({ isSelected }) => ({
  px: 2,
  py: 2,
  color: isSelected ? 'chestnutRose' : 'secondary',
  role: 'button',
}))`
  border-bottom: 2px solid transparent;
  cursor: pointer;
  font-weight: 500;
  font-variant-numeric: tabular-nums;
  ${({ isSelected, theme }) =>
    isSelected &&
    css`
      border-bottom-color: ${theme.colors.chestnutRose};
    `}
`;

const IconWrapper = styled(Box).attrs({
  as: 'li',
  mx: [1, 2],
  bg: 'white',
  role: 'button',
})`
  ${color}
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  svg {
    stroke: ${({ theme, disabled }) =>
      disabled ? theme.colors.tertiary : theme.colors.chestnutRose};
  }
`;

const Ellipsis = () => (
  <Text mx={[1, 2]} display={['none', 'auto']} as="li" color="tertiary">
    ...
  </Text>
);

const Pagination = ({ pages, value, onChange, padding, ...props }) => {
  const pagesArrWithPadding = dropRightWhile(
    dropWhile(pages, page => value - page >= padding),
    page => page - value >= padding,
  );
  if (pages.length === 0) return null;
  return (
    <List as="ul" mx={[-1, -2]} {...props}>
      <IconWrapper
        disabled={value <= pages[0]}
        onClick={() => onChange(pages[0])}
      >
        <FiChevronsLeft />
      </IconWrapper>
      <IconWrapper
        disabled={value <= pages[0]}
        onClick={() => (value > pages[0] ? onChange(value - 1) : undefined)}
      >
        <FiChevronLeft />
      </IconWrapper>
      {value > padding && <Ellipsis />}
      {pagesArrWithPadding.map((page, index) => {
        const isSelected = value === page;
        return (
          <Page
            onClick={event => {
              event.preventDefault();
              onChange(page);
            }}
            isSelected={isSelected}
            as="li"
            key={`page-${index}`}
          >
            {page}
          </Page>
        );
      })}
      {value <= pages.length - padding && <Ellipsis />}
      <IconWrapper
        disabled={value >= pages[pages.length - 1]}
        onClick={() =>
          value < pages[pages.length - 1] ? onChange(value + 1) : undefined
        }
      >
        <FiChevronRight role="button" />
      </IconWrapper>
      <IconWrapper
        disabled={value >= pages[pages.length - 1]}
        onClick={() => onChange(pages[pages.length - 1])}
      >
        <FiChevronsRight />
      </IconWrapper>
    </List>
  );
};

Pagination.defaultProps = {
  padding: 3,
};

export default Pagination;
