import React, { useState } from 'react';
import lazyFirebase from '../../../../../../../firebase/config';
import { useCollection } from 'react-firebase-hooks/firestore';
import Alert from '../../../../../../Alert';
import Pagination from '../../../../../../Pagination';
import styled from 'styled-components';
import Card from '../../../../../../Card';
import { minWidth } from 'styled-system';
import { Box, Flex } from 'rebass';
import { format } from 'date-fns';
import Spinner from '../../../../../../Spinner';
import Text from '../../../../../../Text';

const limit = 20;

const getQuery = ({ member, startAt, limit }) => {
  let query = lazyFirebase
    .firestore()
    .collection('sessions')
    .where('uid', '==', member.uid)
    .orderBy('dateTime', 'desc');
  if (startAt) query = query.startAt(startAt);
  if (limit) query = query.limit(limit);
  return query;
};

const SessionItem = styled(Flex).attrs({ p: 3, as: 'li' })`
  border-bottom: ${({ theme }) => theme.border};
`;

const List = styled(Card).attrs({
  as: 'ul',
})`
  ${minWidth}
  display: flex;
  overflow: hidden;
  overflow-x: auto;
  flex-direction: column;
  & ${SessionItem}::last-of-type {
    border-bottom: 0;
  }
`;

const MembersAdminMemberPageSessions = ({ member }) => {
  const [totalData, totalDataLoading, totalDataError] = useCollection(
    getQuery({ member }),
  );
  const [startAt, setStartAt] = useState(null);
  const [data, loading, error] = useCollection(
    getQuery({ member, startAt, limit }),
  );
  const [page, setPage] = useState(0);
  const isLoading = totalDataLoading || loading;
  const totalSize = totalData?.size;
  const pageCount = Math.ceil(totalSize / limit);
  const goToPage = page => {
    setPage(page);
    const startAtDoc = totalData?.docs[limit * page];
    setStartAt(startAtDoc);
  };
  const isEmpty = totalData?.size === 0;
  return (
    <div>
      {error ||
        (totalDataError && (
          <Alert type="danger">
            {error?.message || error || 'An unexpected error occurred'}
          </Alert>
        ))}
      {isLoading && <Spinner display="block" mx="auto" />}
      {isEmpty && (
        <Text textAlign="center" variant="secondary">
          {member.firstName} {member.lastName} hasn&apos;t logged any sessions
          yet.
        </Text>
      )}
      <List>
        {data?.docs?.map(doc => {
          const session = doc.data();
          return (
            <SessionItem key={doc.id}>
              <Flex mx={-2} width={1}>
                {session.dateTime && (
                  <Box px={2}>
                    {format(
                      session.dateTime.toDate().toISOString(),
                      'MM/DD/YYYY @ HH:mm',
                    )}
                  </Box>
                )}
                {session.ip && <Box px={2}>{session.ip}</Box>}
                {session.userAgent && (
                  <Box px={2} flex={1}>
                    {session.userAgent}
                  </Box>
                )}
                {session.city && session.country && (
                  <Box px={2}>
                    {session.city}, {session.country}
                  </Box>
                )}
              </Flex>
            </SessionItem>
          );
        })}
      </List>
      <Pagination
        pages={[...Array(pageCount || 0).keys()].map(page => page + 1)}
        onChange={page => goToPage(page - 1)}
        value={page + 1}
        my={3}
      />
    </div>
  );
};

export default MembersAdminMemberPageSessions;
